<template>
  <!-- FULL -->
  <v-container fluid v-if="!quickStats" :class="`pt-0${$vuetify.breakpoint.xsOnly ? ' px-1' : ''}`">
    <v-row dense class="pb-4" >
      <v-col cols="12" md="4" lg="3" class="pt-0">
        <v-img
          max-height="100%"
          aspect-ratio="1"
          :src="pic"
        >
        </v-img>
        <div class="mt-n8 text-left">
          <bid-badge
            v-for="bid in bidBadges"
            :key="bid.sb"
            :bid="bid"
            :player="player"
          ></bid-badge>
        </div>
        <rating-badge
          v-if="!$vuetify.breakpoint.mdAndUp"
          :player="player"
          @show-all="scrollToRatings"
          :view="view"
        ></rating-badge>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <v-expand-transition>
          <div v-if="player" class="fill-height">
            <v-card flat color="grey lighten-3" class="fill-height flexcard">
              <v-card-text class="py-0 px-2 grow">
                <v-row>
                  <v-col cols="12" sm="8">
                    <v-row>
                      <v-col cols="12" :class="`text-h4 py-0 ${getDarkerColor(theme.color1, theme.color1Text)}`">
                        {{player.fullname}}{{ isJunior && player.gradYear ? ` ${player.gradYear}` : ''}} <age-division-display v-if="false" :player="player"></age-division-display>
                        <book-mark
                          :ppid="player.id"
                          type="player"
                          :class="`${getDarkerColor(theme.color1, theme.color1Text)}--text`"
                        ></book-mark>
                      </v-col>
                      <v-col cols="12" class="text-h6 py-0">
                        {{cityState}}
                      </v-col>
                      <v-col cols="12" class="py-1" v-if="showMetrics">
                        {{metricsArray | pipeArray}}
                      </v-col>
                      <v-col cols="12" class="py-0 title" v-if="(view.admin || view.college) && isJunior">
                        <div>{{player.committedDisplay}}</div>
                        <div v-if="player.academics.ncaaEligibilityNumber">NCAA Eligibility#: {{player.academics.ncaaEligibilityNumber}}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="!noPoints" cols="12" sm="4" class="py-0">
                    <table>
                      <tr v-for="p in points" :key="p.label" :class="{'headline': p.order === 0}">
                        <td>{{p.label}}:</td>
                        <td>{{p.value | fixed1OrInt}}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="py-0 px-2">
                <v-container fluid class="pa-0">
                  <v-row v-if="true">
                    <!-- PLAYER BUTTONS -->
                    <v-col cols="12">
                      <player-buttons
                        :player="player"
                        :view="view"
                        @show-all="scrollToRatings"
                      ></player-buttons>
                    </v-col>
                  </v-row>
                  <template v-if="memberships.length && false">
                    <v-divider></v-divider>
                    <!-- Memberships -->
                    <v-row>
                      <v-card-text class="px-3 py-2 grow">
                        <h3 class="color1--text">Memberships</h3>
                      </v-card-text>
                    </v-row>
                    <v-row >
                      <v-col
                        :cols="memberships.length > 3 ? 3 : memberships.length === 3 ? 4 : 6"
                        sm="3"
                        v-for="(m, i) in memberships"
                        :key="i"
                        class="py-0"
                      >
                        <v-card
                          class="py-4 align-center justify-center d-flex px-1"
                          :class="{ clickable: !!m.link }"
                          @click.native="goto(m.link)"
                          :color="m.color"
                          height="55px"
                        >
                          <v-img
                            :src="m.url"
                            max-height="50px"
                            contain
                          ></v-img>
                          <template v-if="m.expired && false">
                            <div class="d-flex white v-card--reveal">
                            </div>
                            <div class="d-flex v-card--reveal-text">
                              <v-icon color="rgba(0,0,0,0.7)">fas fa-exclamation-triangle</v-icon>
                            </div>
                          </template>
                        </v-card>
                        <div v-if="m.expired" class="xsmall grey--text text-center">*May be expired</div>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>
      </v-col>
      <v-col>
      <!-- Memberships -->
      <template v-if="memberships.length">
        <v-divider></v-divider>
        <v-row dense>
          <v-col cols="12" class="grey--text">
            Memberships
          </v-col>
        </v-row>
        <v-row dense class="justify-center">
          <v-col
            :cols="memberships.length > 3 ? 3 : memberships.length === 3 ? 4 : 6"
            sm="2"
            v-for="(m, i) in memberships"
            :key="i"
          >
            <v-card
              class="py-4 align-center justify-center d-flex px-1"
              :class="{ clickable: !!m.link }"
              @click.native="goto(m.link)"
              :color="m.color"
              height="65px"
            >
              <v-img
                :src="m.url"
                max-height="50px"
                contain
              ></v-img>
              <template v-if="m.expired && false">
                <div class="d-flex white v-card--reveal">
                </div>
                <div class="d-flex v-card--reveal-text">
                  <v-icon color="rgba(0,0,0,0.7)">fas fa-exclamation-triangle</v-icon>
                </div>
              </template>
            </v-card>
            <div v-if="m.expired" class="xsmall grey--text text-center">*May be expired</div>
          </v-col>
        </v-row>
      </template>

      </v-col>
    </v-row>
    <template v-if="player">
      <!-- Tags -->
      <tag-row :player="player" v-if="user && user.canTag"></tag-row>
      <!-- Videos -->
      <v-expand-transition>
        <v-row dense v-if="player && (view.admin || view.college)">
          <v-col cols="12">
            <v-card>
              <v-toolbar color="color1 color1Text--text">
                <v-toolbar-title>{{player.firstName}}'s Videos</v-toolbar-title>
              </v-toolbar>
              <profile-video-library
                :active="true"
                :player="player"
                :viewOnly="true"
              ></profile-video-library>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- Streams -->
      <v-expand-transition>
        <v-row dense v-if="player && (view.admin || view.college)">
          <v-col cols="12">
            <v-card>
              <v-toolbar color="color1 color1Text--text">
                <v-toolbar-title>{{player.firstName}}'s Match Streams</v-toolbar-title>
              </v-toolbar>
              <profile-stream-library
                :active="true"
                :player="player"
                :viewOnly="true"
              ></profile-stream-library>
            </v-card>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- Tournaments -->
      <v-row dense>
        <v-col cols="12">
          <profile-tournaments :player="player" :view="view"></profile-tournaments>
        </v-col>
      </v-row>
      <!-- INTRO -->
      <v-row dense v-if="intro && (view.admin || view.college)">
        <v-col cols="12">
          <v-card>
            <v-toolbar dense color="color1 color1Text--text">
              <v-toolbar-title class="text-center">{{player.firstName}}'s  Introduction</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-textarea
                v-model="intro"
                color="color3"
                readonly
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Upcoming Tournmanets -->
      <v-row dense v-if="showUpcoming">
        <v-col cols="12">
          <v-card>
            <v-toolbar color="color1 color1Text--text">
              <v-toolbar-title>Upcoming Tournaments</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <player-events
              :tournaments="player.upcomingTournaments"
              :upcoming="true"
              :no-data-text="`${player.firstName} is not currently registered for any tournaments`"
            ></player-events>
          </v-card>
        </v-col>
      </v-row>
      <!-- Extended Info -->
      <v-expand-transition>
        <v-row dense v-if="player && (view.admin || view.college)">
          <v-col cols="12" md="6">
            <info-card
              title="Volleyball Info"
              :data="volleyInfo"
              @verify-click="onVerifyClick"
            >
              <verified-dialog
                ref="verifyDialog"
                :key="player.verifications.length"
                :player="player"
                v-if="player && player.hasVerifications"
              ></verified-dialog>
            </info-card>
          </v-col>
          <v-col cols="12" md="6" v-if="isJunior">
            <info-card
              title="Academic Info"
              :data="academicInfo"
            ></info-card>
          </v-col>
          <!-- Notes -->
          <v-col cols="12" md="6" v-if="player && user">
            <user-notes
              ref="notes"
              :player="player"
            ></user-notes>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- Ratings -->
      <v-expand-transition>
        <v-row dense v-if="player && view.allAdmin && player.ratings && player.ratings.length" ref="ratings">
          <v-col cols="12">
            <player-rating-history
              :player="player"
              :view="view"
            ></player-rating-history>
          </v-col>
        </v-row>
      </v-expand-transition>
    </template>
  </v-container>
  <!-- QUICK STATS -->
  <v-card v-else fluid class="py-0 fill-height flexcard" flat>
    <v-card-text class="grow pa-0">
      <v-row dense>
        <v-col cols="12">
          <div :class="`${darkColor}--text`" class="text-h5 text-sm-h4 pb-1">{{player.fullname}}</div>
          <v-progress-linear :indeterminate="loading" :value="!loading ? 100 : 0" color="color3"></v-progress-linear>
        </v-col>
        <v-col cols="12" v-if="showMetrics && metricsArray.length">
          {{metricsArray | pipeArray}}
        </v-col>
        <v-col cols="12">
          <template v-for="(stat, i) in playerQuickStats" >
            <v-row dense v-for="row in stat" :key="`${row.label}-${i}`" :class="i % 2 > 0 ? 'grey lighten-4' : ''">
              <v-col v-if="!row.hide" class="font-weight-bold py-0 shrink text-no-wrap" :class="{indent: row.indent}">{{row.label}}</v-col>
              <v-col v-if="!row.hide" class="text-right py-0 grow d-flex align-center justify-end">
                <v-skeleton-loader type="text" :loading="loading" :width="loading ? '75px': null" transition="scale-transition">
                  <div>{{row.value}}</div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col v-if="!noPoints && false">
          <v-simple-table dense class='elevation-1'>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Sanctioning Body
                  </th>
                  <th class="text-left">
                    Points
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in points" :key="p.label">
                  <td>{{p.label}}:</td>
                  <td>{{p.value | fixed1OrInt}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pa-0 shrink text-right caption">
      *Only includes VolleyballLife verified results
    </v-card-text>
  </v-card>
</template>

<script>
import ProfileTournaments from './ProfileTournaments'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import LogoUrls from '@/classes/LogoUrls'
import PlayerEvents from './Events.vue'
import AgeDivisionDisplay from './AgeDivisionDisplay'
import BookMark from '@/components/Player/BookMark'
import { formatArray } from '@/Filters'
import { darkerColor } from '@/classes/Dark.js'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
const PlayerButtons = () => import('@/components/Player/PlayerButtons.vue')
const UserNotes = () => import('@/components/Player/UserProfileNoteList.vue')
const ProfileVideoLibrary = () => import('@/components/Player/ProfileVideoLibrary.vue')
const ProfileStreamLibrary = () => import('@/components/Player/ProfileStreamLibrary.vue')
const InfoCard = () => import('@/components/Player/InfoCard.vue')
const VerifiedDialog = () => import('@/components/Player/VerifiedDialog.vue')
const TagRow = () => import('@/components/Player/Tags/TagRow')
const BidBadge = () => import('@/components/Player/BidBadge')
const PlayerRatingHistory = () => import('@/components/Ratings/PlayerRatingHistory')
const RatingBadge = () => import('@/components/Player/RatingBadge')

export default {
  props: ['player', 'profilePic', 'quickStats', 'loading', 'permitted', 'view', 'bidBadges'],
  data () {
    return {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings)
    }
  },
  computed: {
    ...mapGetters(['subdomain', 'theme', 'publicView', 'user', 'noPoints', 'darkColor']),
    bookmarked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.find(f => f.id === this.player.id && f.type === 'player')
    },
    iView () {
      return this.player && this.player.getView(this.user, this.publicView, this.permitted)
    },
    loadingX () {
      return true
    },
    isPlayer () {
      return this.user && this.user.isPlayer
    },
    jProps () {
      return this.player && this.player.jProps.settings
    },
    intro () {
      return this.player && this.player.jProps && this.player.jProps.bio1
    },
    metrics () {
      return this.player && this.player.metrics
    },
    metricsArray () {
      return this.player && this.player.metricsArray
    },
    showUpcoming () {
      const v = this.view || this.iView
      return v.admin || v.vbl ||
        (this.jProps && this.jProps.upcoming && this.jProps.upcoming.includes('public')) ||
        (this.user && this.user.isPlayer && (this.jProps && this.jProps.upcoming && this.jProps.upcoming.includes('players')))
    },
    showMetrics () {
      return this.player && this.player.showMetrics((this.view || this.iView), this.user)
    },
    pic () {
      return (this.player && this.player.profilePic) || this.profilePic || (this.player && this.player.male ? 'https://vblimages.blob.core.windows.net/images/avatar-male.jpg' : 'https://vblimages.blob.core.windows.net/images/avatar-female.jpg')
    },
    cityState () {
      if (this.player) {
        const a = []
        this.player.city && a.push(this.player.city)
        this.player.state && a.push(this.player.state)
        return a.join(', ')
      }
      return null
    },
    isJunior () {
      return this.player.isJunior
    },
    points () {
      const result = [
        { label: 'AAU Points', value: this.player.aauPoints || (this.player.aauNumber ? 0 : 'NA'), order: this.subdomain === 'aau' ? 0 : 100, hide: (!this.player.isJunior || this.theme.props.includes('no-aau-points')) },
        { label: 'AVP Points', value: this.player.avpaPoints || (this.player.avpAmericaNumber ? 0 : 'NA'), order: this.subdomain === 'avp' ? 0 : 101, hide: this.theme.props.includes('no-avp-points') },
        { label: 'BVNE Points', value: this.player.bvnePoints || (this.player.isBvne ? 0 : 'NA'), order: this.subdomain === 'bvne' ? 0 : 102, hide: (!this.player.isJunior || this.theme.props.includes('no-bvne-points')) },
        { label: 'p1440 Points', value: this.player.p1440Points || (this.player.p1440Number ? 0 : 'NA'), order: this.subdomain === 'p1440' ? 0 : 103, hide: (!this.player.isJunior || this.theme.props.includes('no-p1440-points')) },
        { label: 'USAVP Points', value: this.player.usavpPoints || (this.player.p1440Number ? 0 : 'NA'), order: this.subdomain === 'usav' ? 0 : 104, hide: (!this.player.isJunior || this.theme.props.includes('no-usavp-points')) }
      ].filter(f => !f.hide)

      const ps = this.theme.pointSystems
      if (ps.length) {
        ps.forEach((p, i) => {
          const t = this.player.getPoints(p.id)
          t !== 'NA' && result.push({ label: `${p.name} Points`, value: t, order: i })
        })
      }

      return result.sort(firstBy('order'))
    },
    memberships () {
      var mems = (this.player && this.player.memberships) || []
      // if (!this.player.isJunior) mems = mems.filter(f => !f.startsWith('aau'))
      const result = mems.map(m => {
        let x = null
        if (m.startsWith('aau')) {
          x = Object.assign({}, LogoUrls.aau)
          x.hide = !this.player.isJunior
        }
        if (m.startsWith('avp')) {
          x = Object.assign({}, LogoUrls.avpamerica)
        }
        if (m.startsWith('p1440')) {
          x = Object.assign({}, LogoUrls.p1440)
          x.link = `https://play.p1440.org/player/${this.player.id}`
        }
        if (m.startsWith('usav')) {
          x = Object.assign({}, LogoUrls.usav2)
        }
        if (m.startsWith('bvne')) {
          x = Object.assign({}, LogoUrls.bvne)
          x.link = `https://bvne.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'u') {
          x = Object.assign({}, LogoUrls.uova)
          x.link = `https://uova.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'vn') {
          x = Object.assign({}, LogoUrls.volleyNation)
          x.link = `https://volleynation.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'tx') {
          x = Object.assign({}, LogoUrls.txbva)
          x.link = `https://txbva.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'vl') {
          x = Object.assign({}, LogoUrls.vollis)
          x.link = `https://vollisbeach.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'g') {
          x = Object.assign({}, LogoUrls.gav)
          x.link = `https://gav.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'b') {
          x = Object.assign({}, LogoUrls.bvca)
          x.link = `https://bvca.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'tva') {
          x = Object.assign({}, LogoUrls.tva)
          x.link = `https://tva.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'eevb') {
          x = Object.assign({}, LogoUrls.eevb)
          x.link = `https://eastendvolleyball.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'xsv') {
          x = Object.assign({}, LogoUrls.xsv)
          x.link = `https://xsv.volleyballlife.com/player/${this.player.id}`
        }
        if (m === 'v') {
          x = Object.assign({}, LogoUrls.votr)
          x.link = `https://votr.volleyballlife.com/player/${this.player.id}`
        }

        if (x) {
          if (m.includes('expired')) x.expired = true
          x.name = m
        }

        return x
      })
      return result.filter(f => f && !f.hide).sort(firstBy('name'))
    },
    upcoming () {
      return this.player.upcomingTournaments
    },
    playerQuickStats () {
      const base = [
        [{ label: 'Grad Year', value: this.player.gradYear || 'NA', hide: this.player.isAdult }],
        [{ label: 'City', value: this.cityState || 'NA' }],
        [{ label: 'Club', value: this.player.club || 'NA', hide: this.player.isAdult }],
        [{ label: 'Committed?', value: this.player.committedSchool || this.player._committed || 'Unknown', hide: this.player.isAdult }],
        [{ label: 'Videos', value: this.player.videos || 'No', hide: this.player.isAdult }],
        [
          { label: 'National Events*', value: this.nationals.length },
          {
            label: this.nationals.length > 1 ? 'Best Finish' : 'Finish',
            value: this.$options.filters.bestFinish(this.nationals),
            indent: true,
            hide: !this.nationals.length
          }
        ],
        [
          { label: 'Local Events*', value: this.nonNationals.length },
          {
            label: this.nonNationals.length > 1 ? 'Best Finish' : 'Finish',
            value: this.$options.filters.bestFinish(this.nonNationals),
            indent: true,
            hide: !this.nonNationals.length
          }
        ]
      ]

      return base.filter(f => f.find(x => !x.hide))
    },
    volleyInfo () {
      const m = this.player.metrics
      const j = this.player.jProps
      const base = [
        [{ label: 'Dominant Hand', value: m.dominantHand || 'NA', verified: this.player.isVerified('dominantHand') }],
        [{ label: 'Reach', value: m.reach || 'NA', verified: this.player.isVerified('reach') }],
        [{ label: 'Wingspan', value: m.wingspan || 'NA', verified: this.player.isVerified('wingspan') }],
        [{ label: 'Block Jump Touch', value: m.blockTouch || 'NA', verified: this.player.isVerified('blockTouch') }],
        [{ label: 'Approach Jump Touch', value: m.approachTouch || 'NA', verified: this.player.isVerified('approachTouch') }],
        [{ label: 'Star Drill', value: m.starDrill || 'NA', verified: this.player.isVerified('starDrill') }],
        [{ label: 'Beach vs Indoor', value: j.beachVindoor || 'NA', hide: !(m.beach || m.indoor) }],
        [
          { label: 'Beach', value: m.beach ? 'Yes' : 'No' },
          { label: 'Usual Side', value: m.side || 'NA', indent: true, hide: !m.beach },
          { label: 'Preferred Side', value: m.sidePreferred || 'NA', indent: true, hide: !m.beach },
          { label: 'Usual Defense', value: m.defense || 'NA', indent: true, hide: !m.beach },
          { label: 'Preferred Defense', value: m.defensePreferred || 'NA', indent: true, hide: !m.beach }
        ],
        [
          { label: 'Indoor', value: m.indoor ? 'Yes' : 'No' },
          { label: 'Primary Position', value: m.primaryPosition || 'NA', indent: true, hide: !m.indoor },
          { label: 'Preferred Position', value: m.preferredPosition || 'NA', indent: true, hide: !m.indoor },
          { label: 'Experience', value: formatArray(m.positionsPlayed) || 'NA', indent: true, hide: !m.indoor }
        ]
      ]

      return base.filter(f => f.find(x => !x.hide))
    },
    academicInfo () {
      const m = this.player.academics
      const base = [
        [{ label: 'GPA', value: m.gpa || 'NA' }],
        [{ label: 'GPA Scale', value: m.gpaScale || 'NA' }],
        [{ label: 'Class Rank', value: m.classRank ? `${m.classRank}${m.classSize ? '/' + m.classSize : ''}` : 'NA' }],
        [{ label: 'SAT - Math', value: m.satMath || 'NA' }],
        [{ label: 'SAT - EBRW', value: m.satEbrw || 'NA' }],
        [{ label: 'ACT', value: m.act || 'NA' }],
        [{ label: 'Desired Course(s) of Study', value: m.majors.length ? formatArray(m.majors) : 'NA' }],
        [
          { label: 'Preferrences', value: '' },
          { label: 'Region', value: m.regions.length ? formatArray(m.regions) : 'NA', indent: true },
          { label: 'Division', value: m.divisions.length ? formatArray(m.divisions) : 'NA', indent: true },
          { label: 'Enrollment', value: m.enrollment.length ? formatArray(m.enrollment) : 'NA', indent: true },
          { label: 'Selectivity', value: m.selectivity.length ? formatArray(m.selectivity) : 'NA', indent: true }
        ],
        [
          { label: 'High School', value: this.player.highSchool || 'NA' },
          { label: '', value: (m.hsCity && m.hsState) ? `${m.hsCity}, ${m.hsState}` : 'NA', hide: !(this.hsCity && this.hsState) }
        ]
      ]

      return base.filter(f => !f.hide)
    },
    nationals () {
      return this.player && this.player.tournaments ? this.player.tournaments.filter(f => f.national && f.complete) : []
    },
    nonNationals () {
      return this.player && this.player.tournaments ? this.player.tournaments.filter(f => !f.national && f.complete) : []
    }
  },
  methods: {
    addNoteClick () {
      this.$refs.notes && this.$refs.notes.newNote()
    },
    onVerifyClick () {
      this.$refs.verifyDialog && this.$refs.verifyDialog.open()
    },
    goto (link) {
      if (link && link.startsWith('http')) {
        window.open(link, '_blank')
      }
    },
    scrollToRatings () {
      const target = this.$refs.ratings
      if (target) {
        this.$vuetify.goTo(target, {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing
        })
      }
    },
    getDarkerColor (color1, color2) {
      return darkerColor(color1, color2)
    }
  },
  components: {
    ProfileTournaments,
    PlayerEvents,
    PlayerButtons,
    UserNotes,
    ProfileVideoLibrary,
    InfoCard,
    AgeDivisionDisplay,
    VerifiedDialog,
    BookMark,
    ProfileStreamLibrary,
    TagRow,
    BidBadge,
    PlayerRatingHistory,
    RatingBadge
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
  height: 100%
}
.v-card--reveal-text {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%
}
.name {
  font-size: 2em !important;
}
.indent {
  text-indent: 40px;
}
.rating {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
